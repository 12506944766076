body {
  margin: 0;
}
Button {
  text-transform: none;
}
.FooterSocial {
  margin: inherit;
}
.logo {
  height: 55px;
}
.appImg {
  height: 97%;
  margin: 7px;
  position: absolute;
}
.CircleIcon {
  position: relative;
}
.iconBackground {
  height: 500px;
  width: 226px;
  border-radius: 30px !important;
  position: absolute;
  right: 18%;
  top: 18%;
}
.Hero {
  align-items: center;
}
.heroText {
  margin-bottom: 70px;
}
.tumurunler {
  width: 100%;
  max-height: 550px;
  position: inherit;
  position: relative;
}
.SocialMB {
  border-radius: 20%;
}
.banner {
  width: 100%;
  max-height: 550px;
  position: inherit;
  position: relative;
  border-radius: 20px;
  box-shadow: 5px 5px 10px grey;
}
.appDownload {
  width: 90%;
}
.bannerText {
  position: absolute;
  margin: 1rem;
  bottom: 1rem;
  background-color: rgba(65, 65, 65, 0.8) !important;
  height: 25%;
  padding: 20px;
  max-width: 450px;
}
.bannerContainer {
  position: relative;
}
.bannerTypog {
  color: white !important;
}
.bannerButton {
  top: 1rem;
}
.urunlerFoto {
  width: 100%;
  margin-left: 50px;
}
.backgroundIcon {
  height: 60px;
  width: 60px;
  border-radius: 50% !important;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
Link {
  text-transform: none;
}
.hangiUbiHouse {
  justify-content: center;
}
.UbiHouseProListe {
  width: 70%;
}
.artilarimiz {
  color: #6e6e6e;
  letter-spacing: 1px !important;
  padding: 20px;
}
.artilarBaslik {
  color: black;
  margin-bottom: 10px !important;
  margin-top: 5px !important;
}
.artilarimizIkonlar {
  width: 30%;
}
.artilarimizIkonlar2 {
  width: 20%;
}
.navimg {
  width: 31%;
}
.ubihouseliste {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProductRow {
  display: inline;
  justify-content: start;
  padding: 30px;
  margin-top: 30px;
}
.ProductContainer {
  width: 280px;
  margin: 10px;
  height: 400px;
  position: relative;
  border-radius: 20px !important;
}
.ProductMedia {
  height: 150px;
  max-width: 150px;
  margin-left: 60px;
}
.ProductButton {
  size: 20px !important;
  font-size: 20px !important;
  position: absolute;
}
.detaygorselContainer {
  width: 80%;
  margin: 50px;
  border-radius: 20px !important;
  border-color: #dee4ed;
  padding: 10px;
}
.detaygorsel {
  width: 100%;
}
.detayrenksecimiContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}
.detayrenksecimi {
  border-color: #707070;
  margin-right: 10px !important;
  margin-top: 10px !important;
  height: 65px;
  width: 180px;
  justify-content: flex-start !important;
}
.detayrenksecimi:focus {
  border-color: #1969c9;
  color: #1969c9;
}
.detaymateryalsecimi {
  height: 60px;
  width: 300px;
  margin-bottom: 20px !important;
  justify-content: flex-start !important;
}
.detaymateryalsecimiButton {
  height: 30px;
  width: 300px;
  justify-content: flex-start !important;
}
.detaybilgiContainer {
  padding: 60px;
}
.detayAciklamaImg {
  width: 80%;
  height: auto;
  border-radius: 30px;
  box-shadow: 5px 5px 10px rgb(219, 219, 219);
}
.detayAciklamaYazi {
  color: #6c6f73;
  letter-spacing: 0.5px !important;
  font-size: 20px !important;
  padding: 30px;
}
.trayRoots {
  width: 100%;
  max-height: 550px;
  position: inherit;
  position: relative;
  color: rgba(129, 129, 129, 0.8);
  border-radius: 20px;
  background-color: #0b0f19;
  box-shadow: 5px 5px 10px grey;
}
.trayText {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5) !important;
  height: 100%;
  width: 100%;
  border-radius: 20px !important;
}
.trayButton {
  margin-top: 30px !important;
  width: 25%;
  height: 50px;
}
.trayContainer {
  position: relative;
  margin: 20px;
  height: 400px;
}
.trayGrid {
  align-items: center !important;
  justify-content: center !important;
}
.nasilCalisirContainer {
  position: relative;
  align-self: center;
  margin-top: 30px;
}
.teknolojiImg {
  width: 100%;
  max-height: 550px;
  position: inherit;
  position: relative;
  color: rgba(129, 129, 129, 0.8);
  border-radius: 20px;
  background-color: #d9dddd;
}
.AbonelikContainer {
  position: relative;
  margin-top: 50px;
}
.AbonelikSecenekContainer {
  position: absolute;
}
.AbonelikSecenek {
  width: 250px;
  height: 400px;
  background-color: rgba(172, 172, 172, 0.1) !important;
  border-radius: 20px !important;
  margin-top: 150px;
  padding: 15px;
}
.AbonelikImg {
  width: 100%;
}
.AbonelikBackground {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5) !important;
  height: 100%;
  width: 100%;
}
.instagramSvg {
  height: 24px;
}
.HomePageUbiHouseContainer {
  background-color: #f5f5f7 !important;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 20px;
  border-radius: 0px !important;
}
.HomePageUbiTrayContainer {
  text-align: center;
  padding: 20px;
  margin: 20px;
  border-radius: 20px !important;
}
.HomePageUbiEnergyContainer {
  text-align: center;
  padding: 20px;
  margin: 20px;
  border-radius: 20px !important;
}
.HomePageUretimNasilYapilirContainer {
  background-color: #eaeaea !important;
  text-align: center;
  padding: 20px;
  margin: 20px;
  border-radius: 20px !important;
}
.HomePageTeknolojimizContainer {
  background-color: #f0eff5 !important;
  text-align: center;
  padding: 20px;
  margin: 20px;
  border-radius: 20px !important;
  align-items: center;
  justify-content: center;
}
.HomePageAbonelikContainer {
  background-color: #e3f4e0 !important;
  text-align: center;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 0px !important;
}
.AnasayfaUbiHouseGorsel {
  width: 100%;
  max-width: 900px;
}
.HomePageAppImg {
  height: 97%;
  margin: 7px;
}
.HomePageIconBackground {
  height: 400px;
  width: 182px;
  border-radius: 30px !important;
  margin-left: 180px;
  margin-top: 20px;
}
.scrollMenuComponent {
  display: inline-block;
}
.listItemUbiTrayImage {
  height: 90px;
  width: 90px !important;
}
.listItemUbiTrayImageMobile{
  height: 80px;
  width: 80px !important;
}
.listItemArtilarIkonu {
  height: 90px !important;
  width: 90px !important;
}
